class Mixin
{
    objFilter(obj, predicate) {
        const result = {};
        let key;

        for (const key in obj) {
            if (obj.hasOwnProperty(key) && predicate(obj[key], key, obj)) {
                result[key] = obj[key];
            }
        }

        return result;
    }
    
}

export default Mixin;