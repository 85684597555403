import React, { Component } from "react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import "../../static/styles/app.scss";
import SearchPage from "../SearchPage";
import BxApi from "../../services/bx-api";
import Apix from "../../services/apix";
import { TransferContextProvider } from "../../services/transfer-context";
import { fields } from "../../data/entity_fields";
import CallPanel from "../call-panel/call-panel";

console.log('call transfer v0.1.0');

class App extends Component {
    static initiate = false;

    state = {
        page: null,
        entity: null,
        initiate: false,
        entityType: null,
        isTransfer: false,
        currentUser: null,
    };

    componentDidMount() {
        if (App.initiate) return;

        const apix = new Apix();
        const bxApi = new BxApi();

        App.initiate = true;

        new Promise((resolve, reject) => {
            bxApi._isBx() ? resolve() : reject("Not found BX24 connector");
            console.warn("Not found BX24 connector");
        })
            .then(() => bxApi.callData())
            .then(({ options }) => {
                const entityType = {
                    type: String(options.CRM_ENTITY_TYPE).toLowerCase(),
                    id: options.CRM_ENTITY_ID,
                };

                this.setState({ entityType });

                const methods = {
                    currentUser: ["user.current"],
                };

                if (parseInt(entityType.id)) {
                    methods.entity = [
                        `crm.${entityType.type}.get`,
                        { id: entityType.id },
                    ];
                }

                return bxApi.batch(methods);
            })
            .then(({ currentUser, entity }) => {
                const { entityType } = this.state;
                const newState = {
                    page: "search",
                    currentUser: {
                        ID: currentUser.ID,
                        UF_PHONE_INNER: currentUser.UF_PHONE_INNER,
                    },
                };

                if (entity) {
                    newState.entity = {};
                    fields.forEach(({ field }) => {
                        newState.entity[field] = entity[field];
                    });

                    if (entityType.type === "lead") {
                        const nowTime = new Date();
                        const date = new Date(entity.DATE_CREATE);
                        const liveSecond = Math.ceil(
                            (nowTime.getTime() - date.getTime()) / 1000
                        );

                        if (liveSecond < 3600) newState.page = "entity";
                    }
                }

                apix.init(
                    "code-bla-bla",
                    bxApi.getDomain(),
                    currentUser.UF_PHONE_INNER
                );

                this.setState(newState);
            })

            .catch((err) => {
                console.error("ERROR", err);
            });
    }

    changeEntity = (e, field) => {
        const entity = { ...this.state.entity };

        if ("target" in e && e.target instanceof Element)
            entity[e.target.name] = e.target.value;
        else entity[field] = e;

        this.setState({ entity });
    };

    saveEntity = (callback) => {
        const { entity, entityType } = this.state;
        const bxApi = new BxApi();

        bxApi
            .crmEntityUpdate(entityType.type, entityType.id, entity)
            .then((result) => {
                if (result) {
                    callback(true);
                } else {
                    callback(false);
                }
            })
            .catch((err) => {
                callback(false, err);
            });
    };

    setTransfer = (value) => {
        this.setState({ isTransfer: value });
    };

    transfer = async (phone, blind) => {
        const apix = new Apix();

        this.setTransfer(true);

        apix.transfer(phone, blind)
            .then((data) => {
                this.setTransfer(false);
            })
            .catch(async (response) => {
                console.log(response);
                const json = await response.json();
                if (typeof json === "object" && json !== null && json.error) {
                    NotificationManager.error(
                        json.error,
                        "Не удалось сделать перевод",
                        5000
                    );
                }
            });
    };

    setPage = (page) => {
        this.setState({ page });
    };

    render() {
        const { currentUser, page } = this.state;

        if (!currentUser) return "Loading .....";

        return (
            <React.Fragment>
                <TransferContextProvider
                    value={{
                        is: this.state.isTransfer,
                        set: this.setTransfer,
                        get: this.transfer,
                    }}
                >
                    <SearchPage currentUser={currentUser} />

                    <CallPanel page={page} setPage={this.setPage} />
                </TransferContextProvider>
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default App;
