class Apix
{
    static _code = "abcdf1234";
    static _portal = null;
    static _user_phone = null;

    url = 'https://apix.itgrix.ru:9004/v1/bx/forward_data';

    init = (code, portal, phone) => {
        Apix._code = code;
        Apix._portal = portal;
        Apix._user_phone = phone;
    };

    transfer = (phone, blind) => {
        return this._request('transfer', {
            user_phone: Apix._user_phone,
            transfer_to: phone,
            blind: blind ? 'true' : 'false'
        });
    };

    hangup = () => {
        return this._request('hangup', {
            user_phone: Apix._user_phone
        });
    };

    park = () => {
        return this._request('park', {
            user_phone: Apix._user_phone
        });
    };

    unpark = () => {
        return this._request('unpark', {
            user_phone: Apix._user_phone
        });
    };

    _request = (action, params) => {
        const post = {
                action: action,
                code: Apix._code,
                portal: Apix._portal,
                params: params
            },
            requestOpts = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(post)
            };

        // Send request
        return new Promise((resolve, reject) => {
            fetch(this.url, requestOpts)
                .then((response) => {
                    if (!response.ok)
                        throw response;

                    try {
                        return response.json();
                    } catch (e) {
                        resolve(response, e)
                    }
                })
                .then((body) => {
                    resolve(body)
                })
                .catch(function (err) {
                    reject(err)
                });
        })
    }
}

export default Apix;