import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";

import Apix from "../../services/apix";
import { TransferConsumer } from "../../services/transfer-context";
import ErrorComponent from "../../elements/error-component";
import Icon from "../../elements/icon";
import logo from "./itgro_logo.png";
import { ThreeDots } from "@agney/react-loading";

class CallPanel extends Component {
    state = {
        isCallHold: false,
        isCallHoldDisabled: false,
        waitHangup: false,
    };

    hangup = () => {
        if (this.state.waitHangup) {
            return null;
        }

        const apix = new Apix();

        this.setState({ waitHangup: true });

        apix.hangup()
            .then(() => {})
            .catch(async (response) => {
                const json = await response.json();
                if (typeof json === "object" && json !== null && json.error) {
                    NotificationManager.error(
                        json.error,
                        "Не удалось завершить звонок",
                        5000
                    );
                }
            })
            .finally(() => {
                this.setState({ waitHangup: false });
            });
    };

    renderButton = () => {
        return null;
        const { page, setPage } = this.props;

        switch (page) {
            case "search":
                return (
                    <button
                        type="button"
                        onClick={setPage.bind(this, "entity")}
                        className="btn btn--primary btn--with-icon"
                    >
                        <Icon type="info" inheritColor={true} />

                        <span>Информация</span>
                    </button>
                );
            // return <Link to="/call-card/entity" className="btn btn--primary">Информация</Link>;
            case "entity":
                return (
                    <button
                        type="button"
                        onClick={setPage.bind(this, "search")}
                        className="btn btn--primary btn--with-icon"
                    >
                        <Icon type="arrow" inheritColor={true} />

                        <span>Перевести</span>
                    </button>
                );
            // return <Link to="/call-card/" className="btn btn--primary">Перевести</Link>;
            default:
                return "";
        }
    };

    handleCallHold = () => {
        const { isCallHold } = this.state;

        this.setState({ isCallHoldDisabled: true });

        const apix = new Apix();

        const request = isCallHold ? apix.unpark() : apix.park();
        request
            .then((...args) => {
                this.setState({ isCallHold: !isCallHold });
            })
            .catch(async (response) => {
                const json = await response.json();
                if (typeof json === "object" && json !== null && json.error) {
                    NotificationManager.error(
                        json.error,
                        "Не удалось выполнить действие",
                        5000
                    );
                }
            });

        this.setState({ isCallHoldDisabled: false });
    };

    render() {
        const { isCallHold, isCallHoldDisabled, waitHangup } = this.state;

        return (
            <ErrorComponent>
                <div className="btn-group btn-group--fixed btn-group--center">
                    <a
                        href="https://www.bitrix24.ru/apps/?partner_id=236534&search=Y"
                        className="link-itgro"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <img src={logo} alt="itgro_logo" />
                    </a>

                    {this.renderButton()}
                    <button
                        type="button"
                        className={classNames("btn", {
                            "btn--play": isCallHold,
                            "btn--pause": !isCallHold,
                        })}
                        onClick={this.handleCallHold}
                        disabled={isCallHoldDisabled}
                    >
                        <Icon
                            type={isCallHold ? "play" : "pause"}
                            inheritColor
                            inheritSize
                        />
                    </button>

                    <button
                        type="button"
                        className="btn btn--danger btn--with-icon"
                        onClick={this.hangup}
                    >
                        {waitHangup ? (
                            <div style={{ width: 116 }}>
                                <ThreeDots width={30} />
                            </div>
                        ) : (
                            <Fragment>
                                <Icon type="phone" inheritColor={true} />{" "}
                                <span>Завершить</span>
                            </Fragment>
                        )}
                    </button>
                </div>
            </ErrorComponent>
        );
    }
}

export default TransferConsumer(CallPanel);
