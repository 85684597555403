import React, { Component } from 'react'

class ErrorComponent extends Component
{
    state = {
        hasError: false
    };

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);  // eslint-disable-line

        this.setState({
            hasError: true
        })
    };

    render() {
        if (!this.state.hasError)
            return this.props.children;

        // const {lang} = this.p/**/rops;

        return (
            <div className="error-message-component">
                <p className="right__text">
                    UPS! Something go wrong ...
                </p>
            </div>
        )
    }
    
}

export default ErrorComponent;
// export default LangConsumer(ErrorComponent, 'ErrorComponent');