import React, { Component } from "react";

import BxApi from "../../services/bx-api";
import { TransferConsumer } from "../../services/transfer-context";
import Mixin from "../../services/mixin";
import ErrorComponent from "../../elements/error-component";
import Icon from "../../elements/icon";
import recruitment from "./recruitment.svg";
import ListItem from "./ListItem";

class SearchPage extends Component {
    state = {
        search: "",
        searchLoader: false,
        ffUsers: {},
        users: null,
        showEnterPhone: false,
        phone: "",
    };

    timeID = null;

    componentDidMount() {
        const bxApi = new BxApi();

        bxApi
            .getEntityDataUser()
            .then((data) => {
                this.setState({ ffUsers: data }, () => {
                    this.defaultSearch();
                });
            })
            .catch(() => {
                this.defaultSearch();
            });
    }

    defaultSearch() {
        const bxApi = new BxApi();
        const mixin = new Mixin();
        const { ffUsers } = this.state;
        const queries = {};

        queries.favoriteUser = [
            "user.get",
            {
                ACTIVE: true,
                ID: Object.keys(
                    mixin.objFilter(ffUsers, (item) => {
                        return item.favorite;
                    })
                ),
            },
        ];

        if (queries.favoriteUser[1].ID.length < 50)
            queries.frequencyUser = [
                "user.get",
                {
                    ACTIVE: true,
                    ID: Object.keys(
                        mixin.objFilter(ffUsers, (item) => {
                            return !item.favorite && item.frequency > 0;
                        })
                    ),
                },
            ];

        if (
            queries.favoriteUser[1].ID.length +
                queries.frequencyUser[1].ID.length <
            50
        )
            queries.onlineUser = [
                "user.get",
                {
                    sort: "UF_PHONE_INNER",
                    order: "DESC",
                    FILTER: {
                        ACTIVE: true,
                        ">UF_PHONE_INNER": 0,
                        IS_ONLINE: true,
                    },
                },
            ];

        bxApi
            .batch(queries)
            .then((result) => {
                const users = [];
                let i;

                result.favoriteUser.sort(
                    (a, b) => a.PREVIEW_TEXT > b.PREVIEW_TEXT
                );
                result.frequencyUser.sort(
                    (a, b) => ffUsers[b.ID].frequency - ffUsers[a.ID].frequency
                );

                for (i in result)
                    result[i].forEach(
                        ({
                            ID,
                            PERSONAL_PHOTO,
                            LAST_NAME,
                            NAME,
                            WORK_POSITION,
                            UF_PHONE_INNER,
                        }) => {
                            if (
                                users.filter((user) => user.ID === ID)
                                    .length === 0
                            )
                                users.push({
                                    ID,
                                    PERSONAL_PHOTO,
                                    LAST_NAME,
                                    NAME,
                                    WORK_POSITION,
                                    UF_PHONE_INNER,
                                });
                        }
                    );

                this.setState({ users, searchLoader: false });
            })
            .catch((err) => {
                console.error("Error", err);
                this.setState({ searchLoader: false });
            });

        this.setState({ searchLoader: true });
    }

    search = () => {
        const { search } = this.state;

        if (search.length >= 2) {
            const bxApi = new BxApi();

            bxApi
                .searchUser(search)
                .then((result) => {
                    const users = [];
                    const { ffUsers } = this.state;

                    SortUsers(ffUsers, result);

                    result.forEach(
                        ({
                            ID,
                            PERSONAL_PHOTO,
                            LAST_NAME,
                            NAME,
                            WORK_POSITION,
                            UF_PHONE_INNER,
                        }) => {
                            users.push({
                                ID,
                                PERSONAL_PHOTO,
                                LAST_NAME,
                                NAME,
                                WORK_POSITION,
                                UF_PHONE_INNER,
                            });
                        }
                    );

                    this.setState({ users, searchLoader: false });
                })
                .catch((err) => {
                    console.error("Error searchUser", err);
                });

            this.setState({ searchLoader: true });
        } else this.defaultSearch();
    };

    change = (e) => {
        if (this.timeID) {
            clearTimeout(this.timeID);
        }

        this.timeID = setTimeout(() => {
            this.search();
        }, 1300);

        this.setState({ search: e.target.value });
    };

    clearSearchInput = () => {
        this.setState({ search: "" });
        this.defaultSearch();
    };

    ffChange = (userID, favorite, frequency) => {
        const bxApi = new BxApi();
        const { ffUsers } = this.state;

        if (!(userID in ffUsers)) {
            ffUsers[userID] = {
                favorite: false,
                frequency: 0,
            };
        }

        if (favorite)
            ffUsers[userID].favorite = ffUsers[userID].favorite
                ? 0
                : FavoriteCount(ffUsers);

        if (frequency) ffUsers[userID].frequency += 1;

        bxApi.saveEntityDataUser(userID, ffUsers[userID]).catch((err) => {
            console.error("Error saveEntityDataUser", err);
        });

        this.setState({ ffUsers });
    };

    transfer = async (userID = null, phone, blind) => {
        const { transfer } = this.props;
        if (!!userID) {
            this.ffChange(userID, false, true);
        }

        await transfer.get(phone, blind);
    };

    renderUserList = () => {
        const { users, ffUsers } = this.state;
        const { transfer, currentUser } = this.props;

        if (!users || (Array.isArray(users) && users.length === 0)) {
            return (
                <div className="empty-user-list">
                    <div className="empty-user-list__title">
                        К сожалению, по вашему запросу <br /> ничего не найдено
                    </div>
                    <div className="empty-user-list__image">
                        <img src={recruitment} alt="empty user list" />
                    </div>
                </div>
            );
        }

        return users
            .slice(0, 15)
            .map(
                ({
                    ID,
                    PERSONAL_PHOTO,
                    LAST_NAME,
                    NAME,
                    WORK_POSITION,
                    UF_PHONE_INNER,
                }) => {
                    if (currentUser.ID === ID) return "";

                    const favoriteClazz =
                        ID in ffUsers && ffUsers[ID].favorite
                            ? "glyphicon glyphicon-star"
                            : "glyphicon glyphicon-star-empty";

                    return (
                        <ListItem
                            key={ID}
                            ID={ID}
                            PERSONAL_PHOTO={PERSONAL_PHOTO}
                            LAST_NAME={LAST_NAME}
                            NAME={NAME}
                            WORK_POSITION={WORK_POSITION}
                            UF_PHONE_INNER={UF_PHONE_INNER}
                            favoriteClazz={favoriteClazz}
                            transfer={transfer}
                            makeTransfer={this.transfer}
                            ffUsers={ffUsers}
                            ffChange={this.ffChange}
                        />
                    );
                }
            );
    };

    toggleShowEnterPhone = () =>
        this.setState(({ showEnterPhone }) => ({
            showEnterPhone: !showEnterPhone,
        }));

    renderEnterPhone = () => {
        const { phone } = this.state;

        return (
            <li className="user-item">
                <div
                    className="user-item__data"
                    style={{ maxWidth: "70%", flexGrow: 1 }}
                >
                    <input
                        type="text"
                        value={phone}
                        className="newPhoneInput"
                        placeholder="Введите номер"
                        onChange={this.changePhone}
                    />
                </div>

                <div className="user-item__controls">
                    <div className="btn-group btn-group--aic" role="group">
                        <button
                            id="btnGroupDrop1"
                            type="button"
                            className="btn btn--secondary dropdown-toggle btn-sm btn--with-icon"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span>Перевести</span>

                            <Icon type="chevron" inheritColor={true} />
                        </button>

                        <div
                            className="dropdown-menu"
                            aria-labelledby="btnGroupDrop1"
                        >
                            <button
                                className="dropdown-item"
                                onClick={this.transfer.bind(null, null, phone)}
                            >
                                Перевод
                            </button>

                            <button
                                className="dropdown-item"
                                onClick={this.transfer.bind(
                                    null,
                                    null,
                                    phone,
                                    false
                                )}
                            >
                                С уведомлением
                            </button>
                        </div>
                    </div>
                </div>
            </li>
        );
    };

    changePhone = (event) => this.setState({ phone: event.target.value });

    render() {
        const { searchLoader, showEnterPhone } = this.state;

        return (
            <ErrorComponent>
                <div className="form-search">
                    <input
                        type="text"
                        className="form-search__input"
                        placeholder="Поиск"
                        onChange={this.change}
                        value={this.state.search}
                    />

                    <div className="form-search__controls">
                        <button
                            type="button"
                            className="unstyled"
                            onClick={this.search}
                        >
                            <Icon type="magnifier" />
                        </button>

                        <button
                            type="button"
                            className="close"
                            onClick={this.clearSearchInput}
                        />

                        <button
                            className="btn btn_gray"
                            type="button"
                            onClick={this.toggleShowEnterPhone}
                            disabled={showEnterPhone}
                        >
                            Новый номер
                        </button>
                    </div>
                </div>

                {searchLoader && <div>Loading...</div>}

                <ul className="user-list">
                    {showEnterPhone && this.renderEnterPhone()}
                    {this.renderUserList()}
                </ul>
            </ErrorComponent>
        );
    }
}

/**
 * @return {number}
 */
function FavoriteCount(ffUsers) {
    let count = 1;

    Object.entries(ffUsers).forEach(([key, value]) => {
        if (value.favorite) count++;
    });

    return count;
}

function SortUsers(ffUsers, users) {
    users.sort((a, b) => {
        if (!(a.ID in ffUsers)) return 1;

        if (!(b.ID in ffUsers)) return -1;

        const fvb = ffUsers[b.ID].favorite;
        const fva = ffUsers[a.ID].favorite;

        if (fva || fvb) return fvb - fva;

        const fra = ffUsers[b.ID].frequency;
        const frb = ffUsers[b.ID].frequency;

        return frb - fra;
    });
}

export default TransferConsumer(SearchPage);
