import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "@agney/react-loading";

import classNames from "classnames";
import Icon from "../../../elements/icon";
import BxApi from "../../../services/bx-api";

const ListItem = ({
    ID,
    PERSONAL_PHOTO,
    LAST_NAME,
    NAME,
    WORK_POSITION,
    UF_PHONE_INNER,
    transfer,
    ffUsers,
    makeTransfer,
    ffChange,
}) => {
    const [busy, setBusy] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [phones, setPhones] = useState([UF_PHONE_INNER]);
    const [activePhone, setActivePhone] = useState(UF_PHONE_INNER);

    const handleClickOutside = (event) => {
        if (
            $wrapperRef &&
            $wrapperRef.current.contains &&
            !$wrapperRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };
    const $wrapperRef = useRef();

    useEffect(() => {
        const bxApi = new BxApi();

        async function fetchData() {
            bxApi.rest("user.get", { ID }).then((data) => {
                const phoneNumbers = [
                    "UF_PHONE_INNER",
                    "PERSONAL_MOBILE",
                    "PERSONAL_PHONE",
                    "WORK_PHONE",
                ]
                    .map((key) => data[0][key])
                    .filter((value) => !!value && value !== "");

                setPhones(phoneNumbers);
                setActivePhone(phoneNumbers[0]);
            });
        }
        fetchData();

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ID]);

    const callSilentTransfer = async () => {
        setBusy(true);
        await makeTransfer(ID, activePhone, true);
        setBusy(false);
    };
    const callTransfer = async () => {
        setBusy(true);
        await makeTransfer(ID, activePhone, false);
        setBusy(false);
    };

    const callFFChange = () => {
        ffChange(ID, true);
    };

    const toggleIsOpen = () => {
        setIsOpen((value) => {
            return !value;
        });
    };

    const additionalPhone = Array.isArray(phones) && phones.length > 1;

    return (
        <li
            className={classNames("user-item", {
                "user-item--not-number": !activePhone,
            })}
        >
            <div className="user-item__data">
                <div className="user-item__avatar">
                    {PERSONAL_PHOTO && (
                        <img
                            src={PERSONAL_PHOTO}
                            alt={`${LAST_NAME} ${NAME}`}
                        />
                    )}
                </div>

                <div className="user-item__persona">
                    <div className="user-item__persona-name">
                        {" "}
                        {`${LAST_NAME} ${NAME}`}{" "}
                    </div>

                    <div className="user-item__persona-position">
                        {WORK_POSITION}
                    </div>
                </div>
            </div>

            <div className="user-item__status">
                <div
                    className="user-item__status-number"
                    style={{ position: "relative" }}
                    ref={$wrapperRef}
                >
                    <button
                        onClick={additionalPhone ? toggleIsOpen : () => {}}
                        type="button"
                        className="user-item__status-number-button"
                    >
                        <span>{activePhone}</span>&nbsp;
                        {additionalPhone && (
                            <Icon type="chevron" inheritColor={true} />
                        )}
                    </button>

                    {additionalPhone && isOpen && (
                        <div
                            className={classNames(
                                "user-item__status-number-list",
                                {
                                    "user-item__status-number-list_active":
                                        isOpen,
                                }
                            )}
                        >
                            {phones.map((phone) => (
                                <button
                                    key={`${ID}-${phone}`}
                                    className="user-item__status-number-list-item"
                                    onClick={() => {
                                        setActivePhone(phone);
                                        toggleIsOpen();
                                    }}
                                >
                                    {phone}
                                </button>
                            ))}
                        </div>
                    )}
                </div>

                {/** TODO Временно отключаем */}
                {/*<div className="status status--online">online</div>*/}
            </div>
            <div className="user-item__controls">
                <div className="btn-group btn-group--aic" role="group">
                    <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn--secondary dropdown-toggle btn-sm btn--with-icon"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        disabled={!activePhone || transfer.is}
                    >
                        {busy ? (
                            <div style={{ width: 116 }}>
                                <ThreeDots width={30} />
                            </div>
                        ) : (
                            <>
                                <span>Перевести</span>

                                <Icon type="chevron" inheritColor={true} />
                            </>
                        )}
                    </button>

                    {!busy && (
                        <div
                            className="dropdown-menu"
                            aria-labelledby="btnGroupDrop1"
                        >
                            <button
                                className="dropdown-item"
                                onClick={callSilentTransfer}
                            >
                                Перевод
                            </button>

                            <button
                                className="dropdown-item"
                                onClick={callTransfer}
                            >
                                С уведомлением
                            </button>
                        </div>
                    )}

                    <button
                        type="button"
                        className={classNames("btn btn--icon", {
                            "is-favorite":
                                ID in ffUsers && ffUsers[ID].favorite,
                        })}
                        onClick={callFFChange}
                    >
                        <Icon
                            type="star"
                            inheritColor={true}
                            inheritSize={true}
                        />
                    </button>
                </div>
            </div>
        </li>
    );
};
export default ListItem;
